export function formatDate(time){//y-m-d h:m:s
	if (time) {
		var date = new Date(time); //时间戳为10位需*1000，时间戳为13位的话不需乘1000
		let y = date.getFullYear();
		let MM = (date.getMonth() + 1).toString().padStart(2, '0');
		let d = date.getDate().toString().padStart(2, '0');
		let h = date.getHours().toString().padStart(2, '0');
		let m = date.getMinutes().toString().padStart(2, '0');
		let s = date.getSeconds().toString().padStart(2, '0');
		return y + '-' + MM + '-' + d + ' ' + h + ':' + m + ':' + s;
	} else {
		return '0000-00-00  00-00-00'
	}
}
export function formatDateChart(time){//y-m-d h:m:s
	if (time) {
		var date = new Date(time); //时间戳为10位需*1000，时间戳为13位的话不需乘1000
		let y = date.getFullYear();
		let MM = (date.getMonth() + 1).toString().padStart(2, '0');
		let d = date.getDate().toString().padStart(2, '0');
		let h = date.getHours().toString().padStart(2, '0');
		let m = date.getMinutes().toString().padStart(2, '0');
		let s = date.getSeconds().toString().padStart(2, '0');
		return y + '-' + MM + '-' + d + ' ' + h + ':' + m + ':' + s;
	} else {
		return ''
	}
}
export function Timerange(time){//y-m-d
	if (time) {
		var date = new Date(time); //时间戳为10位需*1000，时间戳为13位的话不需乘1000
		let y = date.getFullYear();
		let MM = (date.getMonth() + 1).toString().padStart(2, '0');
		let d = date.getDate().toString().padStart(2, '0');
		return y + '-' + MM + '-' + d;
	} else {
		return '0000-00-00'
	}
}