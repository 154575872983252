// 登录接口
import {
	axios
} from '../../utils/http.js'

const DevApi = {
	DevList: '/v3/DeviceWarning/List2',//设备报警列表
	DevToExcel: '/v3/DeviceWarning/ToExcel2',//设备报警导出exce
	DevEdit: '/v3/DeviceWarning/Edit',//设备报警处理
	DevToDel: '/v3/DeviceWarning/Del',//设备报警删除报警
	DevToLog: '/v3/DeviceWarning/Get',//设备报警删除报警
}

//设备报警列表
export function DevList(data) {
	return axios({
		url: DevApi.DevList,
		method: 'post',
		data:data
	})
}
//设备报警导出exce
export function DevToExcel(data) {
	return axios({
		url: DevApi.DevToExcel,
		method: 'post',
		data:data
	})
}
//设备报警导出exce
export function DevEdit(data) {
	return axios({
		url: DevApi.DevEdit,
		method: 'post',
		data:data
	})
}

//设备报警导出exce
export function DevToDel(data) {
	return axios({
		url: DevApi.DevToDel,
		method: 'post',
		data:data
	})
}
//设备报警导出exce
export function DevToLog(data) {
	return axios({
		url: DevApi.DevToLog,
		method: 'post',
		data:data
	})
}