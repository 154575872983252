<!-- 圆环 -->
<template>
    <div class="cardsRight">
        <div class="cardsRight-item">
            <div class="cardsRight-item-tit">{{cardsTit}}</div>
            <div class="cardsRight-item-conter">
                <slot name="cardsConter"></slot>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props:{
        cardsTit:{
            default: '',
            type: String,
        },
        cardsNum:{
            default: '',
            type: Number,
        }
    },
    components:{
    },
    data() {
        return {

        }
    },
    created() {

    },
    mounted() {

    }
}
</script>
<style lang="scss">
@import url('../assets/scss/cardsRight.scss');
</style>